import React, { useState } from "react";
import { useSelector } from "react-redux";
import ModalLayout from "../../common/ModalLayout";
import { FormDropDownList } from "../../../utils/forms/FormComponents";
import {
  CancellationConfigB2B,
  CancellationConfigB2C,
} from "../../../config/cancellationConfig";
import { Button } from "@progress/kendo-react-buttons";
import { TextArea } from "@progress/kendo-react-inputs";

export default function CancelTrip({
  isAllowedB2B,
  title,
  commentField,
  closeModal,
  handleCancelTrip,
}) {
  const [reasons, setReasons] = useState({
    reason: "",
    subReason: "",
    ...(commentField && { comment: "" }),
  });

  const CancellationConfig = isAllowedB2B
    ? CancellationConfigB2B
    : CancellationConfigB2C;
  const user = useSelector((state) => state.auth?.userDetails);
  return (
    <ModalLayout title={title} onClose={closeModal}>
      <div className="tw-w-[400px]">
        <span className="tw-mb-1">Select Reason</span>
        <FormDropDownList
          data={CancellationConfig.reason}
          textField="label"
          dataItemKey="value"
          value={reasons?.reason}
          onChange={(e) =>
            setReasons({
              reason: e.target.value,
              subReason: "",
              ...(commentField && { comment: "" }),
            })
          }
          style={{ width: "400px" }}
        />
        {reasons?.reason?.value !== "OTHER" && (
          <>
            <span className="tw-mb-1 tw-mt-3 tw-block">Select Sub Reason</span>
            <FormDropDownList
              data={CancellationConfig?.subReason?.filter(
                (sub) => sub?.reason === reasons?.reason?.value
              )}
              textField="label"
              dataItemKey="value"
              value={reasons.subReason}
              onChange={(e) =>
                setReasons((prev) => ({
                  ...prev,
                  subReason: e.target.value,
                }))
              }
              style={{ width: "400px" }}
            />
          </>
        )}
        {commentField && (
          <>
            <span className="tw-mb-1 tw-mt-3 tw-block">Comment</span>
            <TextArea
              value={reasons?.comment}
              onChange={(e) =>
                setReasons((prev) => ({
                  ...prev,
                  comment: e.target.value,
                }))
              }
            />
          </>
        )}
        <Button
          onClick={() => handleCancelTrip(user.email, reasons)}
          disabled={
            !reasons.reason ||
            (reasons?.reason?.value !== "OTHER" && !reasons.subReason) ||
            (reasons?.reason?.value === "OTHER" && !reasons?.comment)
          }
          className="tw-mt-6"
          themeColor={"primary"}
          type={"button"}
        >
          {title}
        </Button>
      </div>
    </ModalLayout>
  );
}
