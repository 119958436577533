export const CancellationConfigB2B = {
  reason: [
    {
      label: "Business Dependency",
      value: "BUSINESS_DEPENDENCY",
    },
    {
      label: "CX Dependency",
      value: "CX_DEPENDENCY",
    },
    {
      label: "Docs Dependency",
      value: "DOCS_DEPENDENCY",
    },
    {
      label: "Towing Dependency",
      value: "TOWING_DEPENDENCY",
    },
    {
      label: "DP Dependency",
      value: "DP_DEPENDENCY",
    },
    {
      label: "Payment Dependency",
      value: "PAYMENT_DEPENDENCY",
    },
    {
      label:"Other",
      value:"OTHER"
    }
  ],

  subReason: [
    {
      label: "Cancelled Before DP Assigned",
      value: "CANCELLED_BEFORE_DP_ASSIGNED",
      reason: "BUSINESS_DEPENDENCY",
    },
    {
      label: "Cancelled After DP Assigned",
      value: "CANCELLED_AFTER_DP_ASSIGNED",
      reason: "BUSINESS_DEPENDENCY",
    },
    {
      label: "Deal Lost",
      value: "DEAL_LOST",
      reason: "BUSINESS_DEPENDENCY",
    },
    {
      label: "Customer address is incorrect",
      value: "CUSTOMER_ADDRESS_INCORRECT",
      reason: "BUSINESS_DEPENDENCY",
    },
    {
      label: "Deal not confirmed yet",
      value: "DEAL_NOT_CONFIRMED_YET",
      reason: "BUSINESS_DEPENDENCY",
    },
    {
      label: "Pick-up scheduled without customer intent slot",
      value: "PICK-UP_SCHEDULED_WITHOUT_CUSTOMER_INTENT_SLOT",
      reason: "BUSINESS_DEPENDENCY",
    },
    {
      label: "Customer denied to handover the car",
      value: "CUSTOMER_DENIED_HANDOVER",
      reason: "CX_DEPENDENCY",
    },
    {
      label: "Customer Not Responding",
      value: "CUSTOMER_NOT_RESPONDING",
      reason: "CX_DEPENDENCY",
    },
    {
      label: "Customer Not Available",
      value: "CUSTOMER_NOT_AVAILABLE",
      reason: "CX_DEPENDENCY",
    },
    {
      label: "Car not ready for pickup",
      value: "CAR_NOT_READY_FOR_PICKUP",
      reason: "CX_DEPENDENCY",
    },
    {
      label: "Delay in handing over the car",
      value: "DELAY_IN_HANDING_OVER_THE_CAR",
      reason: "CX_DEPENDENCY",
    },
    {
      label: "Document - QC reject",
      value: "DOCUMENT_QC_REJECT",
      reason: "DOCS_DEPENDENCY",
    },
    {
      label: "Documents Not Available",
      value: "DOCUMENTS_NOT_AVAILABLE",
      reason: "DOCS_DEPENDENCY",
    },
    {
      label: "Scrap & Not Running Condition : Towing Request",
      value: "SCRAP_NOT_RUNNING_TOWING_REQUEST",
      reason: "TOWING_DEPENDENCY",
    },
    {
      label: "Car Not In Running After DP Reach",
      value: "CAR_NOT_RUNNING_AFTER_DP_REACH",
      reason: "TOWING_DEPENDENCY",
    },
    {
      label: "Delay In Driver Assignment",
      value: "DELAY_IN_DRIVER_ASSIGNMENT",
      reason: "DP_DEPENDENCY",
    },
    {
      label: "Driver Not Available",
      value: "DRIVER_NOT_AVAILABLE",
      reason: "DP_DEPENDENCY",
    },
    {
      label: "Driver delayed in reaching the customer location",
      value: "DRIVER_DELAYED_IN_REACHING_THE_CUSTOMER_LOCATION",
      reason: "DP_DEPENDENCY",
    },
    {
      label: "Payment Not Completed",
      value: "PAYMENT_NOT_COMPLETED",
      reason: "PAYMENT_DEPENDENCY",
    },
  ],
};

export const CancellationConfigB2C = {
  reason: [
    {
      label: "Customer Dependent",
      value: "CUSTOMER_DEPENDENT",
    },
    {
      label: "Client Dependent",
      value: "CLIENT_DEPENDENT",
    },
    {
      label: "Driver Dependent",
      value: "DRIVER_DEPENDENT",
    },
    {
      label: "Others",
      value: "OTHERS",
    },
  ],

  subReason: [
    {
      label: "Car Not Available",
      value: "CAR_NOT_AVAILABLE",
      reason: "CUSTOMER_DEPENDENT",
    },
    {
      label: "Car Not in Moving Condition",
      value: "CAR_NOT_IN_MOVING_CONDITION",
      reason: "CUSTOMER_DEPENDENT",
    },
    {
      label: "Customer Rescheduled to later day",
      value: "CUSTOMER_RESCHEDULED",
      reason: "CUSTOMER_DEPENDENT",
    },
    {
      label: "Customer Not Available",
      value: "CUSTOMER_NOT_AVAILABLE",
      reason: "CUSTOMER_DEPENDENT",
    },
    {
      label: "Customer denied handover of the car",
      value: "CUSTOMER_DENIED_HANDOVER",
      reason: "CUSTOMER_DEPENDENT",
    },
    {
      label: "Customer address is incorrect",
      value: "CUSTOMER_ADDRESS_INCORRECT",
      reason: "CUSTOMER_DEPENDENT",
    },
    {
      label: "Document - Not Available",
      value: "DOCUMENT_NOT_AVAILABLE",
      reason: "CUSTOMER_DEPENDENT",
    },
    {
      label: "Deal Lost",
      value: "DEAL_LOST",
      reason: "CLIENT_DEPENDENT",
    },
    {
      label: "Payment Not Completed",
      value: "PAYMENT_NOT_COMPLETED",
      reason: "CLIENT_DEPENDENT",
    },
    {
      label: "Document - QC reject",
      value: "DOCUMENT_QC_REJECT",
      reason: "CLIENT_DEPENDENT",
    },
    {
      label: "VTF not signed",
      value: "VTF_NOT_SIGNED",
      reason: "CLIENT_DEPENDENT",
    },
    {
      label: "Delivery Payment Not Done",
      value: "DELIVERY_PAYMENT_NOT_DONE",
      reason: "CLIENT_DEPENDENT",
    },
    {
      label: "Ops - Driver shortage",
      value: "OPS_DRIVER_SHORTAGE",
      reason: "DRIVER_DEPENDENT",
    },
    {
      label: "Ops - Driver not willing to go",
      value: "OPS_DRIVER_NOT_WILLING",
      reason: "DRIVER_DEPENDENT",
    },
    {
      label: "The driver has denied completing the task",
      value: "DRIVER_DENIED_TASK",
      reason: "DRIVER_DEPENDENT",
    },
    {
      label: "Bad Weather",
      value: "BAD_WEATHER",
      reason: "OTHERS",
    },
  ],
};
