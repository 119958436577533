import React, { useEffect, useState } from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { useColumns } from "./useColumns";
import GridLoader from "../../common/GridLoader";

export default function AssignDriverTable({
  driverProfiles,
  setSelectedDriverController,
  selectedDriverController,
  fitLoading,
  tripId,
  dataState,
  setDataState,
  setDriverProfiles,
  baseUrl,
  getFitData,
  setLoading,
}) {
  const { columns = [] } = useColumns({ fitLoading });

  const dataStateChange = (e) => {
    setDataState(e.dataState);
  };
  const dataReceived = (data) => {
    const total = data?.total
    const dataCheck = Array.isArray(data?.data?.data) ? data?.data?.data : data?.data || [];
    setDriverProfiles({ data: dataCheck, total: data?.total });
    const driverIds = dataCheck?.map((item) => item?.driverId || "");
    const dataArr = dataCheck || [];
    getFitData(driverIds, (data = dataArr), total);
  };
  const handleRowClick = (e) => {
    setSelectedDriverController(e.dataItem);
  };

  const rowRender = (row, rowProps) => {
    const isSelected =
      rowProps.dataItem.driverId === selectedDriverController?.driverId;

    const rowClass = isSelected ? "selected-row" : "";

    return React.cloneElement(
      row,
      {
        className: `${row.props.className} ${rowClass}`, // Add the class to the existing ones
      },
      row.props.children
    );
  };
  return (
    <div className="tw-mt-10">
      <Grid
        filterable={true}
        sortable={false}
        pageable={true}
        {...dataState}
        data={driverProfiles?.data}
        total={driverProfiles?.total}
        rowRender={rowRender}
        onDataStateChange={dataStateChange}
        onRowClick={handleRowClick}
      >
        {columns.map((column) => (
          <Column
            key={column.id}
            field={column.field}
            title={column.title}
            width={column.width}
            cell={column.cell}
            filterable={column.filterable}
          />
        ))}
      </Grid>
      <GridLoader
        baseUrl={baseUrl}
        columns={columns}
        dataState={dataState}
        onDataReceived={dataReceived}
        useBaseUrlInParams={true}
      />
    </div>
  );
}
