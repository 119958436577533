import { convertEnumToLabel, driverStatusTags } from "../../../utils/helpers";


export const useColumns = ({fitLoading=false}) => {

    const columns = [
        {
          id: "driverId",
          field: "driverId",
          title: "Driver ID",
          filterable: true,
          filterKey: "driverId",
          filterType: "string",
          defaultFilterOperator: "lk",
          width: 120,
        },
        {
          id: "fullName",
          field: "fullName",
          title: "Driver Name",
          filterable: true,
          filterKey: "driverName",
          filterType: "string",
          defaultFilterOperator: "lk",
          width: 250,
        },
        {
          id: "mobileNumber",
          field: "mobileNumber",
          title: "Mobile Number",
          filterable: false,
          filterKey: "mobile_number",
          filterType: "string",
          defaultFilterOperator: "lk",
          width: 200,
        },
        {
          id: "distance",
          field: "distance",
          title: "Distance",
          filterable: false,
          width: 150,
          cell: (cell) => (
            <td
              colSpan={cell.colSpan}
              role="gridcell"
              aria-colindex={cell.ariaColumnIndex}
              aria-selected="false"
              datagridcolindex={cell.columnIndex}
            >
              {cell?.dataItem?.distance &&
                Math.round(cell?.dataItem?.distance * 100) / 100 + " km"}
            </td>
          ),
        },
        {
          id: "driverStatus",
          field: "driverStatus",
          title: "Status",
          filterable: false,
          width: 200,
          cell: (cell) => (
            <td
              colSpan={cell.colSpan}
              role="gridcell"
              aria-colindex={cell.ariaColumnIndex}
              aria-selected="false"
              datagridcolindex={cell.columnIndex}
            >
              {driverStatusTags(cell?.dataItem?.driverStatus)}
            </td>
          ),
        },
        {
          id: "allocationFit",
          field: "allocationFit",
          title: "Fit",
          filterable: false,
          width: 250,
          cell: (cell) => (
            <td
              colSpan={cell.colSpan}
              role="gridcell"
              aria-colindex={cell.ariaColumnIndex}
              aria-selected="false"
              datagridcolindex={cell.columnIndex}
            >
              {fitLoading ? (
                <span className="tw-animate-pulse tw-black/60">loading..</span>
              ) : (
                convertEnumToLabel(cell?.dataItem?.allocationFit)
              )}
            </td>
          ),
        },
      ];
      return {columns}
}
