import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Marker, Polyline, InfoWindow } from "@react-google-maps/api";
import { mapDefaults } from "./defaults";
import GoogleMapsWrapper from "./GoogleMapsWrapper";
import liveDriverSymbol from "../../assets/live-driver.png";
import startPointSymbol from "../../assets/start-point-2.png";
import endPointSymbol from "../../assets/end-point-2.png";
import selectedDriverSymbol from "../../assets/selected-driver.png";
import startPointIcon from "../../assets/start-point.png";
import endPointIcon from "../../assets/end-point.png";
import { convertTo12HourFormat, showErrorMessage } from "../../utils/helpers";
import axiosService from "../../init/axios";
import { APIConfig } from "../../utils/constants/api.constants";
import LoadingSpinner from "../common/LoadingSpinner";

const AssignDriverMap = ({ tripDetails, selectedDriverController }) => {
  const [infoLoading, setInfoLoading] = useState(false);
  const containerStyle = { width: "100%", height: "100%" };
  const mapRef = useRef(null);
  const polylineRef = useRef(null);
  const [selectedDriverDetails, setSelectedDriverDetails] = useState(null);

  const pickup =
    tripDetails.actualSourceLocation || tripDetails.expectedSourceLocation;
  const drop =
    tripDetails.actualDestinationLocation ||
    tripDetails.expectedDestinationLocation;

  const pitstops = tripDetails?.stops?.stops;

  const onLoad = useCallback(
    (map) => {
      mapRef.current = map;
      const bounds = new window.google.maps.LatLngBounds();
      bounds.extend(pickup);
      bounds.extend(drop);
      pitstops?.forEach((stop) =>
        bounds.extend({ lat: stop.lat, lng: stop.lng })
      );
      map.fitBounds(bounds);
    },
    [pickup, drop, pitstops]
  );

  const getForcastReachTime = () => {
    setInfoLoading(true);
    const url =
      process.env.REACT_APP_DRIVER_BASE_URL +
      APIConfig.tripManagement.getForcastReachTime(
        tripDetails?.tripId,
        selectedDriverController?.driverId
      );
    axiosService
      .get(url)
      .then((data) => {

        if (data?.data) {
          setSelectedDriverDetails({
            forecastedReachTime: data?.data.forecastedReachTime,
            ...selectedDriverController,
          });
        }
      })
      .catch((error) => {
        showErrorMessage(error);
      })
      .finally(() => setInfoLoading(false));
  };

  useEffect(() => {
    if (selectedDriverController && mapRef.current) {
      setSelectedDriverDetails(null);
      const bounds = new window.google.maps.LatLngBounds();
      bounds.extend(pickup);
      bounds.extend({
        lat: selectedDriverController.lastKnownLat,
        lng: selectedDriverController.lastKnownLng,
      });
      if (selectedDriverController?.inTripDetails) {
        bounds.extend({
          lat:
            selectedDriverController.inTripDetails.actualDestinationLocation
              ?.lat ||
            selectedDriverController.inTripDetails.expectedDestinationLocation
              ?.lat,
          lng:
            selectedDriverController.inTripDetails.actualDestinationLocation
              ?.lng ||
            selectedDriverController.inTripDetails.expectedDestinationLocation
              ?.lng,
        });
      }
      mapRef.current.fitBounds(bounds);
      if (polylineRef.current) {
        polylineRef.current.setMap(null);
        polylineRef.current = null;
      }
      if (!polylineRef.current) {
        polylineRef.current = new window.google.maps.Polyline({
          path: [
            {
              lat: selectedDriverController.lastKnownLat,
              lng: selectedDriverController.lastKnownLng,
            },
            {
              lat:
                selectedDriverController?.inTripDetails
                  ?.actualDestinationLocation?.lat ||
                selectedDriverController?.inTripDetails
                  ?.expectedDestinationLocation?.lat,
              lng:
                selectedDriverController?.inTripDetails
                  ?.actualDestinationLocation?.lng ||
                selectedDriverController?.inTripDetails
                  ?.expectedDestinationLocation?.lng,
            },
          ],
          strokeColor: "#000",
          strokeOpacity: 1.0,
          strokeWeight: 2,
          map: mapRef.current,
          icons: [
            {
              icon: {
                path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                scale: 3,
                strokeColor: "#000",
              },
              offset: "50%",
            },
          ],
        });
      } else {
        polylineRef.current.setPath([
          {
            lat: selectedDriverController.lastKnownLat,
            lng: selectedDriverController.lastKnownLng,
          },
          {
            lat:
              selectedDriverController?.inTripDetails?.actualDestinationLocation
                ?.lat ||
              selectedDriverController?.inTripDetails
                ?.expectedDestinationLocation?.lat,
            lng:
              selectedDriverController?.inTripDetails?.actualDestinationLocation
                ?.lng ||
              selectedDriverController?.inTripDetails
                ?.expectedDestinationLocation?.lng,
          },
        ]);
      }
    } else if (polylineRef.current) {
      polylineRef.current.setMap(null);
      polylineRef.current = null;
    }
  }, [selectedDriverController, pickup]);

  const options = useMemo(() => mapDefaults.fleetViewMap.options, []);
  const path = [
    { lat: pickup.lat, lng: pickup.lng },
    ...(pitstops?.length > 0
      ? pitstops.map((item) => ({ lat: item.lat, lng: item.lng }))
      : []),
    { lat: drop.lat, lng: drop.lng },
  ];
  return (
    <GoogleMapsWrapper
      containerStyle={containerStyle}
      options={options}
      center={null}
      zoom={null}
      onLoad={onLoad}
      mapRef={mapRef.current}
    >
      {mapRef.current && (
        <>
          <Marker
            position={pickup}
            icon={{
              url: startPointSymbol,
              scaledSize: new window.google.maps.Size(30, 30),
            }}
          />
          <Marker
            position={drop}
            icon={{
              url: endPointSymbol,
              scaledSize: new window.google.maps.Size(30, 30),
            }}
          />
          {pitstops?.length > 0 &&
            pitstops.map((item, index) => (
              <Marker
                key={index}
                position={{ lat: item.lat, lng: item.lng }}
                label={`S${index + 1}`}
              />
            ))}
          <Polyline
            path={path}
            options={{
              strokeColor: "#000",
              strokeOpacity: 0,
              icons: [
                {
                  icon: {
                    path: "M 0,-1 0,1",
                    strokeOpacity: 1,
                    scale: 4,
                  },
                  offset: "0",
                  repeat: "20px",
                },
              ],
              strokeWeight: 2,
            }}
          />

          {(selectedDriverDetails || infoLoading) && (
            <InfoWindow
              onCloseClick={() => setSelectedDriverDetails(null)}
              position={{
                lat: infoLoading
                  ? selectedDriverController?.lastKnownLat
                  : selectedDriverDetails.lastKnownLat,
                lng: infoLoading
                  ? selectedDriverController?.lastKnownLng
                  : selectedDriverDetails.lastKnownLng,
              }}
              className=""
            >
              {infoLoading ? (
                <div className="tw-min-h-[60px] tw-min-w-[200px] tw-flex tw-items-center tw-justify-center">
                  <LoadingSpinner height={50} width={50} color="grey" />
                </div>
              ) : (
                <div className="tw-w-48 tw-overflow-hidden tw-text-xs">
                  <span className="tw-mb-2.5 tw-block tw-font-semibold tw-text-sm">
                    {selectedDriverController?.fullName}
                  </span>
                  {selectedDriverController?.inTripDetails && (
                    <>
                      {selectedDriverDetails?.inTripDetails
                        ?.actualTripEndTime ? (
                        <>
                          <div className="tw-px-1 tw-py-2 custom-border-primary-bottom">
                            <span className="tw-block tw-font-medium tw-text-[#6C6B67]">
                              Trip end time
                            </span>
                            <span className="tw-block tw-font-medium tw-text-[#393737]">
                              {convertTo12HourFormat(
                                selectedDriverDetails?.inTripDetails
                                  .actualTripEndTime
                              )}
                            </span>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="tw-px-1 tw-py-2 custom-border-primary-bottom">
                            <span className="tw-block tw-font-medium tw-text-[#6C6B67]">
                              Expected trip end time
                            </span>
                            <span className="tw-block tw-font-medium tw-text-[#262626]">
                              {convertTo12HourFormat(
                                selectedDriverDetails?.inTripDetails
                                  ?.expectedTripEndTime
                              )}
                            </span>
                          </div>
                        </>
                      )}
                    </>
                  )}
                  <div
                    className={`${
                      selectedDriverController?.inTripDetails &&
                      "tw-px-1 tw-py-2"
                    }`}
                  >
                    <span className="tw-block tw-font-medium tw-text-[#6C6B67]">
                      Forecasted pickup arrival time
                    </span>
                    <span className="tw-block tw-font-medium tw-text-[#393737]">
                      {convertTo12HourFormat(
                        selectedDriverDetails?.forecastedReachTime
                      ) || "N.A."}
                    </span>
                  </div>
                </div>
              )}
            </InfoWindow>
          )}
          {selectedDriverController && (
            <>
              <Marker
                position={{
                  lat: selectedDriverController.lastKnownLat,
                  lng: selectedDriverController.lastKnownLng,
                }}
                onClick={() => {
                  // setSelectedDriverDetails(selectedDriverController)
                  getForcastReachTime();
                }}
                icon={{
                  url:
                    selectedDriverController.driverStatus !== "IN_TRIP"
                      ? liveDriverSymbol
                      : selectedDriverSymbol,
                  scaledSize: new window.google.maps.Size(30, 30),
                }}
              />
              {selectedDriverController?.inTripDetails && (
                <>
                  <Marker
                    icon={{
                      url: endPointIcon,
                      scaledSize: new window.google.maps.Size(40, 40),
                      origin: new window.google.maps.Point(0, 0),
                      anchor: new window.google.maps.Point(20, 20),
                    }}
                    position={{
                      lat:
                        selectedDriverController?.inTripDetails
                          ?.actualDestinationLocation?.lat ||
                        selectedDriverController?.inTripDetails
                          ?.expectedDestinationLocation?.lat,
                      lng:
                        selectedDriverController?.inTripDetails
                          ?.actualDestinationLocation?.lng ||
                        selectedDriverController?.inTripDetails
                          ?.expectedDestinationLocation?.lng,
                    }}
                  />
                  <Marker
                    icon={{
                      url: startPointIcon,
                      scaledSize: new window.google.maps.Size(40, 40),
                      origin: new window.google.maps.Point(0, 0),
                      anchor: new window.google.maps.Point(20, 20),
                    }}
                    position={{
                      lat:
                        selectedDriverController?.inTripDetails
                          ?.actualSourceLocation?.lat ||
                        selectedDriverController?.inTripDetails
                          ?.expectedSourceLocation?.lat,
                      lng:
                        selectedDriverController?.inTripDetails
                          ?.actualSourceLocation?.lng ||
                        selectedDriverController?.inTripDetails
                          ?.expectedSourceLocation?.lng,
                    }}
                  />
                </>
              )}
            </>
          )}
        </>
      )}
    </GoogleMapsWrapper>
  );
};

export default AssignDriverMap;
